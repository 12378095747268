<template>
  <!--begin::Knowledge Base Listing-->
  <div class="knowledge-base-template">
    <ListingTemplate custom-class="knowledge-base-listing">
      <template v-slot:body>
        <ListingHeader>
          <template v-slot:content>
            <v-col cols="4" class="pt-0">
              <h1 class="form-title d-flex margin-auto">
                <v-select
                  :disabled="dataLoading"
                  :items="statusList"
                  v-model="status"
                  hide-details
                  item-color="cyan"
                  class="pt-0 mt-0 listing-status main-listing-status-filter"
                  item-text="text"
                  item-value="value"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title v-text="item.text"></v-list-item-title>
                      <v-list-item-subtitle
                        v-text="item.description"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-chip :color="item.color" :text-color="item.textcolor">
                        <template v-if="item.value == 'all'">{{
                          item.all_tasks
                        }}</template>
                        <template v-else>{{ item.task_status_count }}</template>
                      </v-chip>
                    </v-list-item-action>
                  </template>
                </v-select>
              </h1>
            </v-col>
            <v-col cols="8" class="pt-0 justify-flex-end d-flex margin-auto">
              <template v-if="getPermission('knowledge-base:create')">
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-bold-button"
                  :to="getDefaultRoute('knowledge.base.create')"
                  color="cyan white--text"
                >
                  <v-icon dark left>mdi-plus</v-icon> Create
                </v-btn>
              </template>
              <v-menu
                max-height="400"
                max-width="250"
                offset-y
                left
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :disabled="dataLoading"
                    class="mx-2 custom-bold-button"
                    color="cyan white--text"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon dark>mdi-table-edit</v-icon>
                  </v-btn>
                </template>
                <draggable
                  tag="ul"
                  v-model="defaultColDefs"
                  class="draggable-group"
                  handle=".draggable-drag-icon"
                >
                  <template v-for="cols in defaultColDefs">
                    <li
                      class="draggable-group-item"
                      :key="cols.field"
                      v-if="!cols.checkbox"
                    >
                      <v-checkbox
                        dense
                        v-model="defaultColShow"
                        v-bind:value="cols.field"
                        :label="cols.headerName"
                        :disabled="cols.fixed || dataLoading"
                        color="cyan"
                        hide-details
                        class="pt-2 mt-0"
                      ></v-checkbox>
                      <v-icon
                        class="draggable-drag-icon"
                        small
                        right
                        color="cyan"
                        >mdi-drag</v-icon
                      >
                    </li>
                  </template>
                </draggable>
              </v-menu>
              <PageTips module="task"></PageTips>
            </v-col>
          </template>
        </ListingHeader>
        <ListingSearchCriteria
          v-if="searchEnabled"
          pageModule="Knowledge Base"
          :basicSearchFields="[
            'barcode',
            'reference',
            'subject',
            'group',
            'description'
          ]"
          :allowedSearchFields="searchableArray"
        ></ListingSearchCriteria>
        <!-- <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 60vh; position: relative"
        > -->
        <ListingTable
          :columnCount.sync="defaultColDefs.length"
          :dataLoading.sync="dataLoading"
          :rowData.sync="rowData"
        >
          <template v-slot:thead>
            <thead>
              <tr>
                <template v-for="cols in defaultColDefs">
                  <th
                    v-if="cols.visible"
                    :key="cols.id"
                    class="simple-table-th"
                    :class="{ 'checkbox-width-limit': cols.checkbox }"
                    :style="{ 'max-width': cols.width }"
                  >
                    <template v-if="cols.checkbox"> </template>
                    <template v-else>
                      <div
                        v-if="cols.sortable"
                        class="simple-table-text sortable"
                      >
                        {{ cols.headerName }}
                      </div>
                      <div v-else class="simple-table-text">
                        {{ cols.headerName }}
                      </div>
                      <template v-if="cols.sort">
                        <v-icon
                          class="simple-table-sort"
                          v-if="cols.sort == 'asc'"
                          small
                          right
                          color="cyan"
                          >mdi-sort-ascending</v-icon
                        >
                        <v-icon
                          class="simple-table-sort"
                          v-if="cols.sort == 'desc'"
                          small
                          right
                          color="cyan"
                          >mdi-sort-descending</v-icon
                        >
                      </template>
                    </template>
                  </th>
                </template>
              </tr>
            </thead>
          </template>
          <template v-slot:tbody>
            <tbody class="custom-border-bottom">
              <template v-if="!dataLoading">
                <template v-if="!lodash.isEmpty(rowData)">
                  <tr v-for="(data, index) in rowData" :key="index" link>
                    <template v-for="cols in defaultColDefs">
                      <td
                        v-if="cols.visible"
                        :key="cols.id"
                        :class="{
                          'simple-table-td': !cols.checkbox,
                          'checkbox-width-limit': cols.checkbox
                        }"
                        :style="{ 'max-width': cols.width }"
                        v-on:click="rowClicked(data)"
                      >
                        <template v-if="cols.checkbox"> </template>
                        <template v-else-if="cols.field == 'barcode'">
                          <Barcode :barcode="data.barcode"></Barcode>
                        </template>
                        <template v-else-if="cols.field == 'subject'">
                          <div class="knowledge-base-listing">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Subject: </b>{{ data.subject }}
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Reference: </b>{{ data.reference }}
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'added_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.added_by)"
                            :data.sync="data"
                          >
                            <template v-slot:display_name>
                              {{ data.added_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              {{ data.created_at }}
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDateTime(data.added_at) }}
                            </template>
                          </TableActivity>
                        </template>
                        <template v-else-if="cols.field == 'updated_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.updated_by)"
                            :data.sync="data"
                          >
                            <template v-slot:display_name>
                              {{ data.updated_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              {{ data.modified_at }}
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDateTime(data.updated_at) }}
                            </template>
                          </TableActivity>
                        </template>
                        <template v-else>
                          <div v-html="printRowData(cols, data)"></div>
                        </template>
                      </td>
                    </template>
                  </tr>
                </template>
                <tr v-else-if="!dataLoading">
                  <td :colspan="defaultColDefs.length" class="py-4">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no knowledge base at the moment.
                    </p>
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </ListingTable>
        <!-- </perfect-scrollbar> -->
        <ListingFooter
          :dataLoading.sync="dataLoading"
          :showingFrom.sync="showingFrom"
          :showingTo.sync="showingTo"
          :totalRows.sync="totalRows"
          :currentPage.sync="currentPage"
          :totalPages.sync="totalPages"
        ></ListingFooter>
      </template>
    </ListingTemplate>
  </div>
  <!--end::Knowledge Base Listing-->
</template>

<script>
import draggable from "vuedraggable";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
import PageTips from "@/view/layout/extras/offcanvas/PageTips.vue";

export default {
  mixins: [CommonMixin, ListingMixin],
  name: "knowledge-base-list",
  data() {
    return {
      exportLoading: false,
      dates: [],
      pageModule: "knowledge-base-listing",
      routeAPI: "knowledge-base",
      routeName: "knowledge.base",
      routeDetailName: "knowledge.base.detail",
      status: "all",
      statusList: [],
      moreActions: [],
      bulkActions: []
    };
  },
  components: {
    draggable,
    PageTips,
    Barcode,
    TableActivity,
    ListingTemplate,
    ListingFooter,
    ListingTable,
    ListingHeader,
    ListingSearchCriteria
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Knowledge Base"
      }
    ]);
  },
  beforeMount() {
    const _this = this;
    _this.lodash.assign(_this.queryParams, _this.$route.query);

    _this.defaultColDefs = [
      {
        headerName: "",
        field: "id",
        sort: null,
        visible: true,
        fixed: true,
        sortable: true,
        checkbox: true,
        order: 0,
        width: "50px"
      },
      {
        headerName: "Knowledge Base #",
        field: "barcode",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 1,
        width: "200px"
      },
      {
        headerName: "Subject",
        field: "subject",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 2,
        width: "300px"
      },
      {
        headerName: "Group",
        field: "group",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 4,
        width: "300px"
      },
      {
        headerName: "Created Time",
        child: "created_at",
        field: "added_at",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 6,
        width: "170px"
      },
      {
        headerName: "Last Modified Time",
        child: "modified_at",
        field: "updated_at",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 7,
        width: "170px"
      }
    ];

    _this.defaultColShow = _this.defaultColDefs.map(col => col.field);

    _this.status = _this.$route.query.status || _this.status;

    _this.applyColState();

    _this.paginationPageSize =
      window.localStorage.getItem(_this.pageModule) || 10;
  }
};
</script>
